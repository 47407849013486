import './App.css';
import Define41Component from './pages/MainPage.tsx';
import Hotjar from './hotjar.js';

function App() {

  return (
    <div className="text-center font-body">
      <Hotjar/>
      <Define41Component/>
  </div>
  );
}

export default App;

