import React, { useState, useEffect } from "react";
import BlogSvg from '../Assets/blob-2.gif';
import { SuccessMessage } from "./SuccessMessage.tsx";
import { motion, AnimatePresence } from "framer-motion";
import axios from "axios";

interface WithRqSectionProps {
  isSuccess: boolean;
  onSuccess: () => void;
}

const WithRqSection: React.FC<WithRqSectionProps> = ({ isSuccess, onSuccess }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const [height,setInnerHeight] = useState(window.innerHeight);
  const [email, setEmail] = useState('');
  const POST_URL = process.env.REACT_APP_POST_URL;

  const texts = [
    "Iterate and refine your ideas in real-time, accelerating the path from concept to development.",
    "Conversations flow into structured documentation, keeping your product vision clear and teams aligned.",
    "Generate compelling presentations that communicate your vision effectively to stakeholders.",
    "Expert-led AI creates interactive prototypes, helping you visualize and validate ideas quickly."
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prev) => (prev + 1) % texts.length);
    }, 9000);

    return () => clearInterval(interval);
  }, []);

  const handleButtonClick = () => {
    setIsExpanded(true);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (email) {
      axios.post(POST_URL, { "email" : email })
        .then(response => {
          if (response.status === 200) {
            onSuccess();
          }
        })
        .catch(error => {
          console.error('Error posting email:', error);
        });
    }
  };

  const textVariants = {
    initial: {
      y: 50,
      opacity: 0,
      transition: { type: "spring", stiffness: 300, damping: 30, mass: 0.6 }
    },
    animate: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 300, damping: 30, mass: 0.6 }
    },
    exit: {
      y: -50,
      opacity: 0,
      transition: { type: "spring", stiffness: 300, damping: 30, mass: 0.6 }
    }
  };

  const getNextIndex = (current: number, offset: number) => {
    return (current + offset) % texts.length;
  };

  const inputRef = React.useRef(null);

  React.useEffect(() => {
    if (isExpanded && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isExpanded]);

    return (
        <>
            <main className="w-11/12 h-[450px] md:h-[700px] bg-custom-gradient relative overflow-hidden rounded-3xl mt-28 md:mt-">
                {/* Background GIF */}
                <div className="absolute top-24 md:top-40 inset-0 flex items-center justify-center z-0">
                    <img
                        src={BlogSvg}
                        alt="blob gif"
                        className="w-full h-full object-contain opacity-50"
                    />
                </div>

                {/* Text Section */}
                <div className="absolute inset-0 flex flex-col items-center justify-center z-10 px-8">
                    <h1 className={height <=  600 ? '`text-[#1A202C] text-2xl font-monst md:text-4xl lg:text-5xl text-center font-bold mb-10`':`text-[#1A202C] text-2xl font-monst md:text-4xl lg:text-6xl text-center font-bold mb-10`}>
                        Make Every Idea Count with RQ
                    </h1>

                    {/* Animated Texts */}
                    <div className="relative mt-5 sm:mt-16 font-robo overflow-hidden h-[140px] md:h-[230px] lg:w-2/3 w-full flex flex-col items-center">
                        {/* Current Text */}
                        <AnimatePresence mode="wait">
                            <motion.p
                                key={`current-${currentIndex}`}
                                variants={textVariants}
                                initial="initial"
                                animate="animate"
                                exit="exit"
                                className={height <= 600 ? 'text-slate-800 text-xs md:leading-9 md:text-2xl lg:text-2xl mb-3 md:mb-12 font-medium':'text-slate-800 text-xs md:leading-9 md:text-2xl lg:text-3xl mb-3 md:mb-12 font-medium'}
                            >
                                {texts[currentIndex]}
                            </motion.p>
                        </AnimatePresence>

                        {/* Next Text */}
                        <AnimatePresence mode="wait">
                            <motion.p
                                key={`next-${getNextIndex(currentIndex, 1)}`}
                                variants={textVariants}
                                initial="initial"
                                animate="animate"
                                exit="exit"
                                style={{opacity:"0.9"}}
                                className="text-[#B9B9B9] text-xs w-3/4 md:leading-9 text-opacity-30 md:text-2xl my-3 md:mt-5 font-medium" 
                            >
                                {texts[getNextIndex(currentIndex, 1)]}
                            </motion.p>
                        </AnimatePresence>

                        {/* Upcoming Text */}
                        <AnimatePresence mode="wait">
                            <motion.p
                                key={`upcoming-${getNextIndex(currentIndex, 2)}`}
                                variants={textVariants}
                                initial="initial"
                                animate="animate"
                                exit="exit"
                                className="text-[#B9B9B9] text-xs md:leading-9 text-opacity-30 md:text-2xl my-3 font-medium"
                            >
                                {texts[getNextIndex(currentIndex, 2)]}
                            </motion.p>
                        </AnimatePresence>
                        
                    </div>
                </div>
            </main>
            
            <div className="w-full flex relative items-center justify-center">
            <div className="flex lg:w-3/4 relative justify-center mt-12 sm:mt-8 md:mt-16 lg:mt-8 mb-4 sm:mb-7">
        {isSuccess ? (
          <SuccessMessage />
        ) : (
          <div className={`transition-all duration-300 ease-in-out ${
            isExpanded ? 'w-[280px] sm:w-[350px] md:w-[450px] lg:w-[600px]' : 'w-[200px] sm:w-[250px] md:w-[300px] lg:w-[322px]'
          }`}>
            <form
              onSubmit={handleSubmit}
              className={`flex items-center rounded-[4px] relative overflow-hidden ${
                isExpanded ? 'bg-white pr-2 py-2 shadow-[0px_2px_8.6px_0px_rgba(130,130,130,0.25)] ' : 'bg-[#0060C7] pr-4 hover:shadow-[0px_2px_8px_0px_rgba(0,73,151,0.5)]'
              }`}
            >
              <input
                type="email"
                value={email}
                ref={inputRef}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter Email Id"
                className={`p-2.5 text-sm sm:text-base md:text-lg lg:text-xl outline-none transition-all duration-10 ease-in-out ${
                  isExpanded
                    ? 'opacity-100 w-[60%] sm:w-[65%] md:w-[70%] lg:w-[75%] text-neutral-950 placeholder-neutral-400 pl-5'
                    : 'opacity-0 w-0 p-0'
                } rounded-[4px]`}
                disabled={!isExpanded}
              />
              <button
                type={isExpanded ? 'submit' : 'button'}
                onClick={() => !isExpanded && handleButtonClick()}
                className={`text-sm sm:text-base md:text-lg lg:text-xl font-robo font-semibold text-white transition-all duration-10 ease-out ${
                  isExpanded
                    ? 'bg-[#0060C7] w-[40%] sm:w-[35%] md:w-[30%] lg:w-[25%]'
                    : 'bg-[#0060C7] w-full'
                } rounded-[4px] h-10 sm:h-11 py-7 md:h-12 relative overflow-hidden`}
              >
                <span
                  className={`absolute inset-0 flex items-center justify-center w-full transition-transform duration-300 ease-out ${
                    isExpanded ? 'translate-x-[100%]' : 'translate-x-0'
                  }`}
                >
                  Join the Waitlist
                </span>
                <span
                  className={`absolute inset-0 flex items-center justify-center w-full transition-transform duration-300 ease-out ${
                    isExpanded ? 'translate-x-0' : '-translate-x-[100%]'
                  }`}
                >
                  Join
                </span>
              </button>
            </form>
          </div>
        )}
      </div>
        </div>

    </>
  );
};

export default WithRqSection;