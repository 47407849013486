import React from "react";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedin  } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { Link as ScrollLink } from "react-scroll";
import privacyPolicy from "../Assets/RQ Privacy & Legal Terms.pdf";
// import { ImLinkedin } from "react-icons/im";
import { FaLinkedinIn } from "react-icons/fa";


import { FaDiscord } from "react-icons/fa";
import { RiTwitterXLine } from "react-icons/ri";



const Footer: React.FC = ()=>{

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleOpenPrivacyPolicy = ()=>{
        window.open(`${window.location.origin}/privacy-policy.pdf`, "_blank");
    }

    return (
        <>
            <main className="w-full bg-black flex flex-col items-center">
                <div className="w-11/12 md:w-3/4  mt-10 mb-10 flex flex-col md:flex-row md:justify-between">
                    <div className="flex flex-col items-start mt-4">
                        <h1 className="text-white cursor-pointer font-monst text-5xl font-bold mb-5">
                        <a
                            onClick={()=>scrollToSection('home')}
                        >
                            RQ
                            </a>
                        </h1>
                        <p className="text-[#EAEAEA] mt-1 w-[300px] font-robo font-bold text-start">Turning product ideas into prototypes.</p>
                    </div>
                    <div className="mt-10 flex flex-col-reverse md:flex-col md:mt-0">
                        <div className="md:self-end flex sm:flex-row sm:justify-between w-[220px] mt-5 sm:mb-5">
                            
                            <a className="bg-[#5865F2] p-2 sm:p-3 rounded-full drop-shadow-[0_0_4px_rgba(255,255,255,0.5)]" href="https://www.instagram.com/think41corp/" target="_blank"
                                >
                                <FaDiscord style={{fontSize:"28px",color: "white" }}/>
                            </a>
                            
                            <a className="bg-black p-2.5 sm:p-3.5 rounded-full drop-shadow-[0_0_4px_rgba(255,255,255,0.5)] ml-8 sm:ml-0" href="https://www.instagram.com/think41corp/" target="_blank">
                                <RiTwitterXLine style={{fontSize:"24px",color:"white"}}/>
                            </a>

                            <a className="bg-[#1361A6] p-2.5 sm:p-3.5 rounded-full drop-shadow-[0_0_4px_rgba(255,255,255,0.5)] ml-8 sm:ml-0" href="https://www.linkedin.com/company/think41/" target="_blank">
                                <FaLinkedinIn style={{ fontSize: "24px", color: "white" }} />      
                            </a>
                        </div>

                        <div className="w-[300px] lg:w-[350px] md:w-[280px] flex flex-row justify-between font-robo">
                            <a  
                                onClick={()=>scrollToSection('features')}
                                className="text-white font-semibold cursor-pointer"
                                >
                                Features
                            </a>

                            <a
                                onClick={()=>scrollToSection('about')}
                                className="text-white font-semibold cursor-pointer"
                                >
                                About us
                            </a>
                            <a
                            //  href="#privacy"
                             onClick={handleOpenPrivacyPolicy}
                             className="text-white font-semibold cursor-pointer"
                            >
                                Privacy Policy
                            </a>
                        </div>
                    </div>
                </div>
                <div className="w-11/12 md:w-3/4">
                    <p className="text-[#727272] text-start mb-10 font-robo">
                        <span className="text-xs ">© Copyright 2024.</span>
                        <span className="text-xs "> All rights reserved.</span>
                    </p>
                </div>
            </main>
        </>
    )
}

export default Footer;
