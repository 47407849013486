import React from 'react';

const VoiceVision: React.FC = () => {
  return (
    <div className="flex relative items-center justify-center gap-10 px-2 py-2 max-w-wrap text-center text-violet-800 sm:text-sm md:text-base mt-16 xl:mb-4 lg:mb-2 sm:mt-24 sm:mb-2 md:mt-28 lg:mt-14 xl:mt-20">
      {/* Container for Voice and Vision */}
      <div className="flex items-center justify-center gap-1 w-full max-w-xs bg-blue-50 rounded-full text-lg text-purple-600 p-1 px-3">
        {/* Voice Section */}
        <div className="flex items-center font-semibold">
          <svg xmlns="http://www.w3.org/2000/svg" width="45" height="17" viewBox="0 0 12 13" fill="none">
                  <g clipPath="url(#clip0_2060_2528)">
                    <path d="M4.43017 2.5L5.66079 5.25L8.36816 6.5L5.66079 7.75L4.43017 10.5L3.19955 7.75L0.492188 6.5L3.19955 5.25L4.43017 2.5ZM4.43017 4.915L3.93792 6L2.86975 6.5L3.93792 7L4.43017 8.085L4.92242 7L5.9906 6.5L4.92242 6L4.43017 4.915ZM9.35265 5L8.73242 3.63L7.38366 3L8.73242 2.375L9.35265 1L9.96796 2.375L11.3216 3L9.96796 3.63L9.35265 5ZM9.35265 12L8.73242 10.63L7.38366 10L8.73242 9.375L9.35265 8L9.96796 9.375L11.3216 10L9.96796 10.63L9.35265 12Z" fill="url(#paint0_linear_2060_2528)"/>
                  </g>
                  <defs>
                    <linearGradient id="paint0_linear_2060_2528" x1="5.90692" y1="1" x2="5.90692" y2="12" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#9600FF"/>
                      <stop offset="1" stopColor="#0060C7"/>
                    </linearGradient>
                    <clipPath id="clip0_2060_2528">
                      <rect width="11.814" height="12" fill="white" transform="translate(0 0.5)"/>
                    </clipPath>
                  </defs>
                </svg>
          <span className="text-sm md:text-lg">Voice</span>
        </div>

        {/* Vision Section */}
        <div className="flex mr-4 items-center font-semibold text-[#9600FF]">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/5c7da1d2f75d4bdaac2d3841828a451e/327788288dd63cfb7f6002ebe90127fd6b39f485b4455c6b3e0cae86ad354508?apiKey=5c7da1d2f75d4bdaac2d3841828a451e&"
            className="object-contain shrink-0 w-5 aspect-square"
            alt="Vision   "
          />
          <span className="text-sm md:text-lg px-1">Vision</span>
        </div>
      </div>
    </div>
  );
};

export default VoiceVision;
