import { useEffect, useCallback } from 'react';

export const useFeatureToWithRqScroll = (featuresRef, withRqRef) => {
  const handleLastFeatureScroll = useCallback(() => {
    // Ensure we have references to both features and withRq sections
    if (!featuresRef.current || !withRqRef.current) return;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        // We're interested in the features section
        if (entry.target === featuresRef.current) {
          // Check if the last feature is in view and user is scrolling
          const lastFeature = entry.target.querySelector('#feature-4');
          
          if (lastFeature) {
            const lastFeatureObserver = new IntersectionObserver((lastFeatureEntries) => {
              lastFeatureEntries.forEach((lastFeatureEntry) => {
                // When last feature is almost fully in view and user continues scrolling
                if (lastFeatureEntry.isIntersecting && lastFeatureEntry.intersectionRatio >= 0.8) {
                  // Scroll to WithRQ section smoothly
                  withRqRef.current.scrollIntoView({ 
                    behavior: 'smooth',
                    block: 'start'
                  });
                }
              });
            }, {
              threshold: 0.8 // Trigger when 80% of last feature is visible
            });

            // Observe the last feature
            lastFeatureObserver.observe(lastFeature);

            // Cleanup
            return () => {
              lastFeatureObserver.disconnect();
            };
          }
        }
      });
    }, {
      root: null,
      threshold: 0.1 // Adjust as needed
    });

    // Observe the features section
    observer.observe(featuresRef.current);

    // Cleanup
    return () => {
      observer.disconnect();
    };
  }, [featuresRef, withRqRef]);

  useEffect(() => {
    handleLastFeatureScroll();
  }, [handleLastFeatureScroll]);

  return null;
};