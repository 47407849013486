import React, { useState } from "react";
import { color, motion, Variants } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import ImageIcon from '@mui/icons-material/Image';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import { Link as ScrollLink } from "react-scroll";
import brainstormImage from '../Assets/brainstorm.svg';
import prdImage from '../Assets/prd.svg';              
import slidesImage from '../Assets/slide.svg';         
import prototypeImage from '../Assets/prototype.svg'; 
import ReactPageScroller from 'react-page-scroller';
import { useRef } from "react";
import "./hideScrollbar.css"

interface DescriptionText {
    text: string;
    color?: string; 
}

interface BoxProps {
    children: React.ReactNode;
    variants: Variants;
    className?: string;
    styles?: React.CSSProperties;
}

interface ImageBoxProps {
    varients:Variants;
    hasButton:Boolean;
    buttonColor:string;
    toFeature?:string;
    imageUrl: string;
    altText: string;
    buttonAction?:string;
}

interface TextBox {
    varients: Variants;
    iconText:string;
    titleText:string;
    descriptionTexts:DescriptionText[];
}
  
const Box: React.FC<BoxProps> = ({ children, variants, className,styles }) => (
    <motion.div
      variants={variants}
      style={styles}
      className={`w-[300px] h-[400px] rounded-lg flex items-center text-white ${className}`}
    >
      {children}
    </motion.div>
);

const ImageBox: React.FC<ImageBoxProps> = ({ varients, hasButton, buttonColor,buttonAction, toFeature, imageUrl, altText }) => {
    const [isMobile,setIsMobile] = React.useState(window.innerWidth < 500);

    return (
      <Box 
        variants={varients}
        className="shadow-[0px_2px_8.6px_0px_rgba(130,130,130,0.25)] relative flex justify-center items-center w-[320px] h-[200px] md:w-[80%] md:h-[350px] lg:w-[350px] lg:h-[320px] xl:w-[550px] xl:h-[450px] rounded-xl"
        styles={isMobile ? {height:'220px'}:{}}
      >
        <img 
          src={imageUrl} 
          alt={altText}
          className="w-full h-full object-cover sm:object-cover rounded-2xl"
        />
        {hasButton && (
          <ScrollLink 
            to={toFeature}
            smooth={true}
            duration={500}
            offset={-80} // Add offset to adjust final scroll position
            spy={true} // Enable active state tracking
            style={{backgroundColor:`${buttonColor}`}}
            className={`py-2 px-5 rounded-lg font-robo text-white absolute top-4 right-4 cursor-pointer`}
          >
            {buttonAction}
          </ScrollLink>
        )}
      </Box>
    );
};


  const TextBox: React.FC<TextBox> = ({varients, iconText, titleText, descriptionTexts}) => {
    return (
      <Box 
        variants={varients}
        className=" w-[340px] h-[340px] md:w-[80%] md:h-[400px] lg:w-[500px] lg:h-[350px] xl:w-[650px] xl:h-[500px] p-4 md:p-6 lg:p-8 flex flex-col sm:justify-start sm:items-center lg:items-start text-center lg:text-start"
          >
            <div className="text-[#9600FF] bg-blue-50 font-robo rounded-full py-2 px-2  flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" className="mr-1" width="45" height="17" viewBox="0 0 12 13" fill="none">
                <g clipPath="url(#clip0_2060_2528)">
                  <path d="M4.43017 2.5L5.66079 5.25L8.36816 6.5L5.66079 7.75L4.43017 10.5L3.19955 7.75L0.492188 6.5L3.19955 5.25L4.43017 2.5ZM4.43017 4.915L3.93792 6L2.86975 6.5L3.93792 7L4.43017 8.085L4.92242 7L5.9906 6.5L4.92242 6L4.43017 4.915ZM9.35265 5L8.73242 3.63L7.38366 3L8.73242 2.375L9.35265 1L9.96796 2.375L11.3216 3L9.96796 3.63L9.35265 5ZM9.35265 12L8.73242 10.63L7.38366 10L8.73242 9.375L9.35265 8L9.96796 9.375L11.3216 10L9.96796 10.63L9.35265 12Z" fill="url(#paint0_linear_2060_2528)"/>
                </g>
                <defs>
                  <linearGradient id="paint0_linear_2060_2528" x1="5.90692" y1="1" x2="5.90692" y2="12" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9600FF"/>
                    <stop offset="1" stopColor="#0060C7"/>
                  </linearGradient>
                  <clipPath id="clip0_2060_2528">
                    <rect width="11.814" height="12" fill="white" transform="translate(0 0.5)"/>
                  </clipPath>
                </defs>
              </svg>
              <span className="pr-10 font-robo text-sm md:text-lg">
                {iconText}
              </span>
            </div>
            <h1 style={{fontWeight:'550'}} className="text-xl my-2 font-monst lg:my-3 lg:mb-3 xl:mt-16 md:text-3xl md:mt-3 lg:text-2xl text-black sm:leading-relaxed">{titleText}</h1>
            <p className="text-black text-sm text-center lg:text-justify font-robo md:text-xl md:mt-4 lg:text-xl xl:text-2xl leading-relaxed sm:mb-5">
              {descriptionTexts.map((item, index) => (
                <span key={`${iconText}-${index}`} style={item.color ? { color: item.color } : { color: '#727272' }}>
                  {item.text}
                </span>
              ))}
            </p>
          </Box>
    );
};


interface featuresProps{
  mainContentView:Boolean;
  featuresContentView: Boolean;
  withRqView: Boolean;
  aboutSectionView: Boolean;
}

const Features: React.FC<featuresProps> = ({ mainContentView , featuresContentView , withRqView, aboutSectionView })=>{

  const featureScrollerRef = useRef(null);
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 1024);
  const [height,setHeight] = useState(window.innerHeight);
  const [width,setWidth] = useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
      console.log("Height: ",height)
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [scrollPosition, setScrollPosition] = React.useState(0);

  React.useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const featureImages = {
    brainstorm: brainstormImage, 
    prd: prdImage,        
    slides: slidesImage,     
    prototype: prototypeImage 
  };

    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.1
    });

    const [ref2, inView2] = useInView({
        triggerOnce: true,
        threshold: 0.1
    });

    const [ref3, inView3] = useInView({
        triggerOnce: true,
        threshold: 0.1
    });

    const [ref4, inView4] = useInView({
        triggerOnce: true,
        threshold: 0.1
    });
    
    const containerVariants: Variants = {
        hidden: { 
          opacity: 0 
        },
        visible: { 
          opacity: 1,
          transition: {
            duration: 0.3,
            when: "beforeChildren",
            staggerChildren: 0.2
          }
        }
      };
    
      const leftBoxVariants: Variants = {
        hidden: { 
          x: '-50%', 
          opacity: 0 
        },
        visible: {
          x: 0,
          opacity: 1,
          transition: {
            type: "spring",
            damping: 25,
            stiffness: 100
          }
        }
      };
    
      const rightBoxVariants: Variants = {
        hidden: { 
          x: '50%', 
          opacity: 0 
        },
        visible: {
          x: 0,
          opacity: 1,
          transition: {
            type: "spring",
            damping: 25,
            stiffness: 100
          }
        }
      };

      const descriptionTexts: DescriptionText[] = [
        { text: "Conversational AI ", color: "#9600FF" },
        {text:'captures your ideas in a natural, intuitive way—through '},
        {text:'chat or voice',color:'#9600FF'},
        { text: ". Share your thoughts freely, spark new ideas, and let AI simplify the process, making brainstorming and requirement gathering  more productive and engaging." }
      ];
      
      const descriptionTexts2: DescriptionText[] = [
        { text: "Convert conversations into clear, " },
        { text: "structured documentation", color: "#9600FF" },
        { text: " with ease. Our AI captures key requirements, organizes features into " },
        { text:"detailed specs",color:"#9600FF"},
        { text:", and generates "},
        { text:"development-ready documentation ",color:"#9600FF"},
        { text:"in just one click."},
      ];

      //   
      
 
      const descriptionTexts3: DescriptionText[] = [
        { text: "Turn ideas into clear and " },
        { text: "engaging slide decks", color: "#9600FF" },
        { text: ". AI creates polished presentations from your inputs, ensuring a smooth and impactful " },
        { text:"flow of information ",color:"#9600FF"},
        { text:"on every slide."}
      ];

      
      const descriptionTexts4: DescriptionText[] = [
        { text: "Expert-led AI ",color: "#9600FF" },
        { text: "converts your vision into " },
        { text: " interactive prototypes",color:'#9600FF' },
        { text:". Our specialists and AI work together to create "},
        { text:"clickable interfaces",color:"#9600FF"},
        { text:", helping you test and refine your ideas with confidence."},
      ];

    return (
        <>
            <main className="w-screen h-screen flex overflow-x-hidden flex-col items-center relative " style={{ overflow: "visible" }}>
              <div className="w-11/12 md:mt-12 xl:mt-14">
                  <div className="lg:w-4/5 xl:w-3/4 mx-auto">
                      <h1
                        className="flex flex-wrap text-2xl sm:text-4xl mt-28 mb-3 sm:mb-0 lg:mt-24 justify-center md:text-4xl lg:text-4xl bg-clip-text font-monst text-transparent bg-gradient-to-r from-[#37265E] from-[4.47%] via-[#6600B3] via-[32.18%] to-[#0060C7] to-[95.78%]"
                        style={width > 1280 ? {lineHeight:"1.2",fontWeight:"600",fontSize:'65px'} : {lineHeight:"1.2",fontWeight:"600"}} 
                        >
                        Your Vision, Documented & Designed
                      </h1>
                </div>
              </div>
              {
                width > 500 ? <div className="absolute w-[300px] h-[300px] md:w-[400px] md:h-[400px] lg:w-[500px] lg:h-[500px]  bg-purple-300 -left-[10%] top-[33%] opacity-20 rounded-[50px] z-0 select-none"  style={{transform:'rotate(56.13deg)'}} ></div>:''
              }
              
              <div className="absolute w-[150px] h-[150px] md:w-[200px] md:h-[200px] lg:w-[200px] lg:h-[200px] bg-purple-300 opacity-10 md:opacity-100 md:bg-blue-50 -right-[2%] top-[75%] md:top-[65%] rounded-[45px] md:rounded-[50px] z-0 select-none"  style={{transform:'rotate(135deg)'}} ></div>
              <div className={`h-[700px] md:h-[900px] xl:h-[650px] w-screen mx-auto example overflow-y-scroll snap-y snap-mandatory scroll-smooth relative`}>
                {/* <div className="pseudo-element"></div> */}
                <div className="w-11/12 mx-auto h-full snap-always snap-start flex flex-row justify-center items-center overflow-hidden">
                    <motion.div
                        ref={ref}
                        className="w-full mx-auto flex justify-between items-start lg:w-11/12 scroll-smooth"
                        variants={containerVariants}
                        initial="hidden"
                        animate={inView ? "visible" : "hidden"}
                    >
                        <div className="w-full h-full">
                            <div className="flex flex-col items-center lg:flex-row lg:justify-evenly">
                                <ImageBox 
                                    varients={leftBoxVariants} 
                                    hasButton={false} 
                                    buttonColor="#C81B1B" 
                                    buttonAction="End Conversation" 
                                    toFeature="feature-2" 
                                    imageUrl={featureImages.brainstorm}
                                    altText="AI-powered brainstorming session visualization"
                                />
                                <TextBox 
                                    varients={rightBoxVariants} 
                                    iconText="Powered by Voice"
                                    titleText="AI-Powered Brainstorming"
                                    descriptionTexts={descriptionTexts}
                                />
                            </div>
                        </div>
                    </motion.div>
                </div>

                <div id="feature-2" className="w-11/12 mx-auto h-full snap-always snap-start grid place-items-center overflow-hidden">
                    <motion.div
                        ref={ref2}
                        className="w-full mx-auto flex items-center lg:w-11/12 scroll-smooth"
                        variants={containerVariants}
                        initial="hidden"
                        animate={inView2 ? "visible" : "hidden"}
                    >
                        <div className="justify-center w-full">
                            <div className="flex flex-col items-center lg:flex-row lg:justify-evenly">
                                <ImageBox 
                                    varients={leftBoxVariants} 
                                    hasButton={false} 
                                    buttonColor="#C81B1B" 
                                    imageUrl={featureImages.prd}
                                    altText="PRD generation process visualization"
                                />
                                <TextBox 
                                    varients={rightBoxVariants} 
                                    iconText="Instant Docs"
                                    titleText="One Click Documentation"
                                    descriptionTexts={descriptionTexts2}
                                />
                            </div>
                        </div>
                    </motion.div>
                </div>

                <div className="w-11/12 mx-auto h-full snap-always snap-start grid place-items-center overflow-hidden">
                    <motion.div
                        ref={ref3}
                        className="w-full mx-auto flex items-center lg:w-11/12 scroll-smooth"
                        variants={containerVariants}
                        initial="hidden"
                        animate={inView3 ? "visible" : "hidden"}
                    >
                        <div className="w-full">
                            <div className="flex flex-col items-center lg:flex-row lg:justify-evenly">
                                <ImageBox 
                                    varients={leftBoxVariants} 
                                    hasButton={false} 
                                    buttonColor="#9600FF" 
                                    buttonAction="Generate Prototype" 
                                    toFeature="feature-4" 
                                    imageUrl={featureImages.slides}
                                    altText="Slide deck creation visualization"
                                />
                                <TextBox 
                                    varients={rightBoxVariants} 
                                    iconText="Pitch Perfect"
                                    titleText="Fast-Track Slide Creation"
                                    descriptionTexts={descriptionTexts3}
                                />
                            </div>
                        </div>
                    </motion.div>
                </div>

                <div id="feature-4" className="w-11/12 mx-auto h-full snap-always snap-start grid place-items-center overflow-hidden">
                    <motion.div
                        ref={ref4}
                        className="w-full mx-auto flex items-center lg:w-11/12 scroll-smooth"
                        variants={containerVariants}
                        initial="hidden"
                        animate={inView4 ? "visible" : "hidden"}
                    >
                        <div className="justify-center w-full">
                            <div className="flex flex-col items-center lg:flex-row lg:justify-evenly">
                                <ImageBox 
                                    varients={leftBoxVariants} 
                                    hasButton={false} 
                                    buttonColor="#592CC3" 
                                    imageUrl={featureImages.prototype}
                                    altText="Prototype generation visualization"
                                />
                                <TextBox 
                                    varients={rightBoxVariants} 
                                    iconText="Rapid Prototypes"
                                    titleText="Effortless Prototype Generation"
                                    descriptionTexts={descriptionTexts4}
                                />
                            </div>
                        </div>
                    </motion.div>
                </div>
              </div>
            </main>
        </>
    )
  }
  
  export default Features;

  // style={{background: "linear-gradient(0deg, #9600FF 0%, #9600FF 100%)",filter: "blur(100px)",opacity:"0.15"}}