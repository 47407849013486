
import React from "react"

const AboutSection: React.FC = ()=>{
    
    const [height,setHeight] = React.useState(window.innerHeight);

    return(
        <>
            <main className="w-11/12 pt-16 md:mb-40 mb-14">
                <h1 className="text-center text-2xl sm:text-4xl lg:text-6xl font-monst md:text-4xl font-bold mt-24 md:mt-24 mb-16 bg-clip-text leading-normal text-transparent bg-gradient-to-r from-[#37265E] from-[4.47%] via-[#6600B3] via-[31.86%] to-[#0060C7] to-[95.78%]">
                    About Us
                </h1>

                {/* <h1 className="md:text-4xl font-robo text-2xl text-center" style={{fontWeight:'500'}}>
                    The Product Language From Think41
                </h1> */}
                <div className="relative md:my-20 my-10 flex flex-col items-center justify-center">
                    <h1 className="absolute text-[170px] md:text-[350px] sm:text-[200px] xs:text[150px] font-bold text-[#0060C7] opacity-5 z-0 pointer-events-none select-none font-monst">RQ</h1>

                    <div className="relative z-10  md:w-11/12 text-center">
                        <p className={height <= 600 ? 'px-4 lg:px-8 md:px-0 text-center md:text-2xl lg:text-2xl text-sm font-robo sm:text-xl text-[#727272]':'px-4 lg:px-8 md:px-0 text-center md:text-2xl lg:text-3xl text-sm font-robo sm:text-xl text-[#727272]'}>
                            {/* RQ is a tool designed to simplify the way product managers and teams transform ideas into functional digital experiences. Its mission is to empower innovators to focus on creativity and execution rather than tedious documentation. <span className="text-[#9600FF]">RQ streamlines product development</span> by transforming ideas into detailed PRDs and interactive prototypes using advanced conversational AI. */}
                            {/* Our mission is to let innovators focus on innovation, while we handle the transformation of <span className="text-[#9600FF]">ideas into development-ready assets</span>.RQ helps teams articulate their product vision through natural conversation. We streamline the path from concept to development by <span className="text-[#9600FF]">creating clear documentation, presentations, and interactive prototypes</span>. Our expert-led AI lets you focus on what matters most - <span className="text-[#9600FF]">building great products.</span> */}
                            Think41 is a dynamic, well-funded <span className="text-[#9600FF]">GenAI organization</span> at the forefront of transforming collaboration between humans and AI. We’re building innovative systems and software designed to enhance human experiences and create more fulfilling work environments. Our suite of agents, systems, and platforms empowers us to operate seamlessly as one cohesive team, <span className="text-[#9600FF]">delivering higher-quality results</span> with unmatched speed and efficiency.

                        </p>
                    </div>
                </div>
                {/* <button className="bg-[#0060C7] text-white px-24 rounded-md py-3 mb-5 mt lg:text-xl font-semibold font-robo hover:shadow-[0px_2px_8px_0px_rgba(0,73,151,0.5)] "> 
                    <a target="blank" href="https://www.think41.com/">Know more</a>
                </button> */}
            </main>
        </>
    )
}

export default AboutSection;