import React, { useEffect, useState } from 'react';
import Header from '../components/Header.tsx';
import MainContent from '../components/MainContent.tsx';
import VideoComponent from '../components/VideoComponent.tsx';
import Features from '../components/Features.tsx';
import WithRqSection from '../components/WithRqSection.tsx';
import AboutSection from '../components/AboutSection.tsx';
import Footer from '../components/Footer.tsx';
import ReactPageScroller from 'react-page-scroller';
import { useRef } from 'react';
import { useCallback } from 'react';
import { Element, scroller } from 'react-scroll';
import TitleComponent from '../components/TitleComponent.tsx';
import "../components/hideScrollbar.css";
import { useInView } from 'react-intersection-observer';
import { useFeatureToWithRqScroll } from '../components/useScrollToSection.js';


function useDebounce(callback, delay) {
  const timeoutRef = useRef(null);

  return useCallback((...args) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      callback(...args);
    }, delay);
  }, [callback, delay]);
}

const Define41Component: React.FC = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const pageScrollerRef = useRef<any>(null);
  const [blockOuterScroll, setBlockOuterScroll] = useState(false);
  const [isExpanded,setIsExpanded] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [scrolling, setScrolling] = useState(false);
  const [visibleSection, setVisibleSection] = useState<string | null>(null);


  const handleSuccess = () => {
    setIsSuccess(true);
  }


  const [mainContentRef, mainContentView] = useInView({
    triggerOnce: false, // Change to false to track continuous visibility
    threshold: 0.5 // Increase threshold to require more of the section to be in view
  });

  const [featuresRef, featuresContentView] = useInView({
    triggerOnce: false,
    threshold: 0.5
  });

  const [withRqRef, withRqView] = useInView({
    triggerOnce: false,
    threshold: 0.5
  });

  const [aboutRef, aboutSectionView] = useInView({
    triggerOnce: false,
    threshold: 0.5
  });

  useFeatureToWithRqScroll(featuresRef,withRqRef)


  return (
    <>
      <Header 
        onSuccess={handleSuccess} 
        setIsExpanded={setIsExpanded}
      />
      <div className='example h-screen overflow-y-scroll snap-y snap-mandatory scroll-smooth '>
          <Element name="home" id="home">
            <div ref={mainContentRef} className="flex h-screen snap-start snap-always w-full flex-col overflow-x-hidden relative items-center bg-white page-container">
              <MainContent isSuccess={isSuccess} onSuccess={handleSuccess} isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
            </div>
          </Element>
          {/* <div className='flex w-full relative flex-col items-center bg-white page-container'>
            <TitleComponent/>
          </div> */}
          <div ref={featuresRef} className="flex snap-start snap-always w-full overflow-x-hidden relative flex-col items-center bg-white page-container" id="features">
            <Features 
              mainContentView={mainContentView}
              featuresContentView={featuresContentView}
              withRqView={withRqView}
              aboutSectionView={aboutSectionView}
            />
          </div>

          <div ref={withRqRef} className="flex h-screen snap-start snap-always w-full overflow-x-relative flex-col items-center bg-white page-container">
            <WithRqSection isSuccess={isSuccess} onSuccess={handleSuccess} />
          </div>

          <div ref={aboutRef} className="flex h-screen snap-start snap-always w-full overflow-x-hidden relative flex-col items-center justify-between bg-white page-container" id="about">
            <AboutSection />
            <Footer />
          </div>
      </div>
    </>
  );
};

export default Define41Component;