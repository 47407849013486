import React, { useState } from 'react';
import ContentSection from './ContentSection.tsx';
import { SuccessMessage } from './SuccessMessage.tsx';
import ArrowSvg from '../Assets/arrow.gif';
import axios from 'axios';

interface MainContentProps {
  isSuccess: boolean;
  onSuccess: () => void;
  isExpanded?: boolean;
  setIsExpanded:()=>void;
}

const MainContent: React.FC<MainContentProps> = ({ isSuccess, onSuccess,isExpanded,setIsExpanded }) => {
  // const [isExpanded, setIsExpanded] = useState(false);
  const [email, setEmail] = useState('');
  const POST_URL = process.env.REACT_APP_POST_URL;

  const handleButtonClick = () => {
    setIsExpanded(true);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (email) {
      axios.post(POST_URL, { "email": email })
        .then(response => {
          if (response.status === 200) {
            onSuccess();
          }
        })
        .catch(error => {
          console.error('Error posting email:', error);
        });
    }
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };
  


  const inputRef = React.useRef(null);

  React.useEffect(() => {
    if (isExpanded && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isExpanded]);

  return (
    <div className="w-full h-screen px-4 md:px-6 lg:px-8">
      <main className="w-full mx-auto h-[67%] lg:h-[55%]">
        <ContentSection />
        <h2 className="text-base sm:text-2xl md:text-3xl lg:text-3xl xl:text-4xl font-medium font-robo text-center xl:mt-6 sm:mt-3 md:mt-2.5 text-gray-900">
          <span className="text-neutral-500 font-medium">Intelligent </span>
          <span className="text-gray-900 font-semibold">documentation</span>
          <span className="text-neutral-500 font-medium">, </span>
          <span className="text-gray-900 font-semibold">presentations</span>
          <span className="text-neutral-500 font-medium"> and </span>
          <span className="text-gray-900 font-semibold">prototypes</span>
          {/* <span className="text-neutral-500 font-medium"> - intelligently crafted</span> */}
        </h2>
      </main>

      <div className="flex relative justify-center mt-4 sm:mt-8 md:mt-16 lg:mt-0 lg:mb-8 mb-4 sm:mb-7">
        {isSuccess ? (
          <SuccessMessage />
        ) : (
          <div className={`transition-all duration-300 ease-in-out ${
            isExpanded ? 'w-[280px] sm:w-[350px] md:w-[450px] lg:w-[640px]' : 'w-[200px] sm:w-[250px] md:w-[300px] lg:w-[322px]'
          }`}>
            <form
              onSubmit={handleSubmit}
              className={`flex items-center font-robo rounded-[4px] relative overflow-hidden ${
                isExpanded ? 'bg-white pr-2 py-2 shadow-[0px_2px_8.6px_0px_rgba(130,130,130,0.25)] ' : 'bg-[#0060C7] pr-4 hover:shadow-[0px_2px_8px_0px_rgba(0,73,151,0.5)]'
              }`}
            >
              <input
                type="email"
                ref={inputRef}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter Email Id"
                className={`p-2.5 text-sm sm:text-base md:text-lg lg:text-xl font-robo outline-none transition-all duration-10 ease-in-out ${
                  isExpanded
                    ? 'opacity-100 w-[60%] sm:w-[65%] md:w-[70%] lg:w-[75%] bg-white text-neutral-950 placeholder-neutral-400 pl-5'
                    : 'opacity-0 w-0 p-0'
                } rounded-md`}
                disabled={!isExpanded}
              />
              <button
                type={isExpanded ? 'submit' : 'button'}
                onClick={() => !isExpanded && handleButtonClick()}
                className={`text-sm sm:text-base md:text-lg lg:text-xl font-semibold text-white transition-all duration-10 ease-out ${
                  isExpanded
                    ? 'bg-[#0060C7] w-[40%] sm:w-[35%] md:w-[30%] lg:w-[25%]'
                    : 'bg-[#0060C7] w-full'
                } rounded-md py-7 relative overflow-hidden`}
              >
                <span
                  className={`absolute inset-0 flex items-center justify-center w-full transition-transform duration-300 ease-out ${
                    isExpanded ? 'translate-x-[100%]' : 'translate-x-0'
                  }`}
                >
                  Join the Waitlist
                </span>
                <span
                  className={`absolute inset-0 flex hover:shadow-[0px_2px_8px_0px_rgba(0,73,151,0.5)] items-center justify-center w-full transition-transform duration-300 ease-out ${
                    isExpanded ? 'translate-x-0' : '-translate-x-[100%]'
                  }`}
                >
                  Join
                </span>
              </button>
            </form>
          </div>
        )}
      </div>
     <div className="flex lg:mb-10 md:mb-0 sm:mb-0 mb-0 items-center justify-center">
            <img
              src={ArrowSvg}
              onClick={()=>scrollToSection("features")}
              alt="blob gif"
              className="object-contain cursor-pointer lg:h-[12%] lg:w-[12%] md:h-[20%] md:w-[20%] sm:h-[40%] sm:w-[20%] xs:h-[30%] xs:w-[30%] h-[20%] w-[20%] xl:h-[10%] xl:w-[10%] xl:mt-10"
            />
          </div>
    </div>
  );
};

export default MainContent;