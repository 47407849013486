import React, { useState, useEffect, useRef } from 'react';
import { Link as ScrollLink } from "react-scroll"
import axios from "axios";

interface HeaderProps {
  onSuccess: () => void;
  setIsExpanded: ()=>void;
}

const Header: React.FC<HeaderProps> = ({ onSuccess,setIsExpanded }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [email, setEmail] = useState("");
  const POST_URL = process.env.REACT_APP_POST_URL;
  const headerRef = React.useRef(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(()=>{ 

    let fun = ()=>{
      console.log(document.getElementById("header")?.offsetHeight)
    }

    let id = window.addEventListener('resize',fun)

    return ()=>{
      window.removeEventListener('resize',fun)
    }
    
  },[])

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setEmail("");
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (email) {
      try {
        const response = await axios.post(POST_URL, { "email" :email });
        if (response.status === 200) {
          closePopup();
          onSuccess();
        }
      } catch (error) {
        console.error("Error submitting email:", error);
      }
    }
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleSignUpClick = ()=>{
    setTimeout(()=>{
      setIsExpanded(true)
    },100);
  }

  return (
    <>
    <div id="header" ref={headerRef} className="w-11/12 fixed top-8 lg:top-4 sm:top-3 md:top-8 z-50 left-1/2 transform font-robo -translate-x-1/2 lg:w-10/12 md:w-11/12 sm:w-11/12 mx-auto " ref={menuRef}>
      <header className="flex items-center justify-between pe-3 ps-2 sm:ps-2 sm:pe-4 md:ps-5 md:pe-5 lg:ps-7 lg:pe-7 pt-4 pb-4 bg-blue-50 rounded-3xl max-w-8xl mx-auto relative z-20">
        {/* Left Section: Hamburger Menu and Logo */}
        <div className="flex items-center lg:gap-4 md:gap-4 gap-0.25 sm:gap-2">
          {/* Hamburger Icon */}
          {/* <button
            className="block md:hidden p-2 text-neutral-800 rounded-lg transition-colors"
            onClick={toggleMenu}
            aria-label="Toggle Menu"
            aria-expanded={isMenuOpen}
          >
            <div className={`w-4 h-0.5 bg-neutral-800 mb-0.5 transition-all duration-300 ${isMenuOpen}`}></div>
            <div className={`w-4 h-0.5 bg-neutral-800 mb-0.5 transition-all duration-300 ${isMenuOpen }`}></div>
            <div className={`w-4 h-0.5 bg-neutral-800 transition-all duration-300 ${isMenuOpen}`}></div>
          </button> */}

          {/* Logo */}
          <div className="text-xl font-bold font-monst lg:text-4xl cursor-pointer md:text-xl sm:text-xs bg-gradient-to-r from-indigo-950 via-violet-800 to-blue-600 text-transparent bg-clip-text">
            <a
              onClick={()=>scrollToSection('home')}
              >
              RQ
            </a>
          </div>
        </div>

        {/* Center Section: Navigation Links (Desktop) */}
        <nav className="hidden md:flex font-robo ps-28 items-center text-base justify-center gap-x-12">
          <button
            onClick={()=>scrollToSection('features')}
            className="font-semibold cursor-pointer text-xl text-neutral-800 hover:text-purple-600 transition-colors"
          >
            Features
          </button>
          <button
            onClick={()=>scrollToSection('about')}
            className="font-semibold cursor-pointer text-xl text-neutral-800 hover:text-purple-600 transition-colors"
          >
            About us
          </button>
        </nav>

          {/* Right Section: Sign Up Button */}
          <button
          onClick={handleSignUpClick}
          className="sm:px-14 sm:py-2 py-1 px-4 md:px-6 font-robo lg:py-3 lg:px-12 font-semibold lg:text-xl md:text-base sm:text-base text-white bg-[#0060C7] rounded-[4px] transition-all hover:shadow-[0px_2px_8px_0px_rgba(0,73,151,0.5)] " 
        >
            <a
             href='#home'>

              Join
            </a>
          </button>
        </header>

      {/* Mobile Menu */}
      <div 
        className={`
          absolute 
          top-full 
          left-0 
          right-0 
          mt-2
          bg-blue-50 
          rounded-2xl 
          shadow-lg 
          overflow-hidden
          transition-all 
          duration-300 
          ease-in-out
          md:hidden
          z-30
          ${isMenuOpen 
            ? 'opacity-100 translate-y-0 max-h-48' 
            : 'opacity-0 -translate-y-4 max-h-0'
          }
        `}
      >
        <nav className="flex flex-col p-4">
          <a
            className="px-4 py-3 cursor-pointer font-medium text-neutral-800 hover:text-purple-600 rounded-lg transition-colors"
            onClick={() => {
                setIsMenuOpen(false)
                scrollToSection('features')
            }}
          >
            Features
          </a>
          <hr/>
          <a
            className="px-4 py-3 cursor-pointer font-medium text-neutral-800 hover:text-purple-600 rounded-lg transition-colors"
            onClick={() => {
              setIsMenuOpen(false)
              scrollToSection('about')
            }}
          >
            About us
          </a>
        </nav>
      </div>
    </div>

      {/* Popup */}
      {isPopupOpen && (
<div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 p-4">
      <div className="bg-white rounded-lg w-full relative
      w-[80%] h-[260px]
        xs:w-[90%] xs:h-[280px]
        sm:w-[85%] sm:h-[320px]
        md:w-[75%] md:h-[360px]
        lg:w-[65%] lg:h-[400px]
        xl:w-[55%] xl:h-[440px]
        2xl:w-[50%] 2xl:h-[480px]">
        
        {/* Close Button */}
        <button
          onClick={closePopup}
          className="absolute text-gray-600 hover:text-gray-900 transition-colors
          top-2 right-4 text-xl
            xs:top-2 xs:right-4 xs:text-xl
            sm:top-3 sm:right-4 sm:text-2xl
            md:top-4 md:right-6 md:text-2xl
            lg:top-5 lg:right-5 lg:text-3xl
            xl:top-6 xl:right-6 xl:text-3xl"
          aria-label="Close"
        >
          &times;
        </button>

        {/* Content Container */}
        <div className="flex flex-col justify-center h-full
        px-6 py-6
          xs:px-4 xs:py-6
          sm:px-6 sm:py-8
          md:px-8 md:py-10
          lg:px-10 lg:py-12
          xl:px-12 xl:py-14">
          
          <h2 className="text-center font-monst font-bold
          text-xl mb-10
            xs:text-xl xs:mb-4
            sm:text-2xl sm:mb-10
            md:text-3xl md:mb-12
            lg:text-3xl lg:mb-16
            xl:text-4xl xl:mb-20">
            Be Among the first to Experience
          </h2>

          <form onSubmit={handleSubmit} className="w-full">
            <input
              type="email"
              placeholder="Enter your email"
              className="w-full border border-gray-200 rounded-lg outline-none 
                font-robo focus:ring-2 focus:ring-blue-500 focus:border-transparent
                px-3 py-2 mb-4
                xs:px-3 xs:py-2 xs:mb-3
                sm:px-4 sm:py-2.5 sm:mb-4
                md:px-4 md:py-3 md:mb-5
                lg:px-5 lg:py-3.5 lg:mb-6
                xl:px-6 xl:py-4 xl:mb-7"
              required
            />
            
            <button
              type="submit"
              className="w-full bg-blue-600 text-white rounded-lg 
                hover:shadow-[0px_2px_8px_0px_rgba(0,73,151,0.5)] transition-colors font-robo
                py-2
                xs:py-2
                sm:py-2.5
                md:py-3
                lg:py-3.5
                xl:py-4"
            >
              Join the Waitlist
            </button>
          </form>
        </div>
      </div>
    </div>
)}
      </>
    );
};

export default Header;