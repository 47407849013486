import React from 'react';

export const SuccessMessage = () => {
  return (
    <section className="flex flex-col items-center lg:p-8 md:p-6 sm:p-4 xs:p-4 p-2 xl:w-[80%] max-w-screen-lg lg:w-[80%] md:w-[80%] sm:w-[80%] xs:w-[70%] w-[90%] lg:w-screen  rounded-[12px] sm:rounded-[24px] md:rounded-[24px] lg:rounded-[24px] xl:rounded-[24px] border border-[#F0F5FC] xs:rounded-[20px] shadow-[0px_1.236px_4.944px_0px_rgba(150,166,188,0.25)]" style={{
      background:'linear-gradient(90deg, rgba(203, 222, 243, 0.50) 0%, rgba(169, 170, 240, 0.50) 34.5%, rgba(189, 157, 246, 0.50) 69%, rgba(229, 194, 254, 0.50) 100%)'
    }}>
      <div className='w-full flex-col items-center'>
        <svg className='mx-auto mb-4' xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
        <path d="M21 3.5C11.375 3.5 3.5 11.375 3.5 21C3.5 30.625 11.375 38.5 21 38.5C30.625 38.5 38.5 30.625 38.5 21C38.5 11.375 30.625 3.5 21 3.5ZM21 35C13.2825 35 7 28.7175 7 21C7 13.2825 13.2825 7 21 7C28.7175 7 35 13.2825 35 21C35 28.7175 28.7175 35 21 35ZM29.0325 13.265L17.5 24.7975L12.9675 20.2825L10.5 22.75L17.5 29.75L31.5 15.75L29.0325 13.265Z" fill="#0060C7"/>
      </svg>
        <h1 className=" sm:text-xl font-monst text-base md:text-4xl font-semibold text-neutral-950 text-center leading-snug break-words">
          Thank You!
        </h1>
      </div>
      <p className="lg:mt-4 md:mt-4 font-robo sm:mt-2 mt-1 text-xs sm:text-base md:text-lg font-normal text-neutral-800 text-center leading-relaxed md:break-words">
        Welcome to our community!  Expect to see updates in your inbox soon.
      </p>
    </section>
  );
};
