import React, { useState, useEffect } from 'react';
import { ArrowRightIcon } from 'lucide-react';
import './ContentSection.css';
import VoiceVision from './VoiceVision.tsx';

interface AnimationState {
  conversation: boolean;
  documents: boolean;
  prototypes: boolean;
  arrows: {
    first: boolean;
    second: boolean;
  };
}

const ContentSection: React.FC = () => {
  const [animationState, setAnimationState] = useState<AnimationState>({
    conversation: false,
    documents: false,
    prototypes: false,
    arrows: { first: false, second: false }
  });

  const [width,setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const animationSequence = async () => {
      while (true) {
        // Reset state
        setAnimationState({
          conversation: false,
          documents: false,
          prototypes: false,
          arrows: { first: false, second: false }
        });

        await new Promise(resolve => setTimeout(resolve, 800));
        
        // Show Conversation
        setAnimationState(prev => ({ ...prev, conversation: true }));
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        // Show first arrow and Documents
        setAnimationState(prev => ({ ...prev, arrows: { ...prev.arrows, first: true } }));
        
        setAnimationState(prev => ({ ...prev, documents: true }));
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        // Show second arrow and Prototypes
        setAnimationState(prev => ({ ...prev, arrows: { ...prev.arrows, second: true } }));
        
        setAnimationState(prev => ({ ...prev, prototypes: true }));
        
        // Wait before resetting
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
    };

    animationSequence();
  }, []);

  return (
    <div className="flex w-11/12 mt-12 flex-col mx-auto h-[85%] lg:h-[70%] items-center font-monst lg:mt-20 md:mx-auto sm:mx-0 lg:px-4 md:px-0 sm:px-0 xs:px-0 mb-4 lg:pb-5 md:pb-5 sm:pb-4 lg:mb-10">
      <VoiceVision/>
      <h1 className="lg:text-3xl md:text-4xl sm:text-3xl text-2xl font-bold text-center mt-1 sm:mb-3 md:mb-3 lg:mb-7 mb-0">
        Turn ideas into
      </h1>

      <div className="flex flex-col justify-evenly items-center lg:mt-0 h-[85%] lg:flex-row lg:justify-evenly lg:items-center lg:gap-2 md:gap-2 sm:gap-1 gap-1">
        <div className='flex font-monst flex-col justify-around items-center lg:flex-row lg:justify-around lg:items-center'>
          <span 
            className={`text-4xl font-bold sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl bg-gradient-to-r from-indigo-950 via-violet-800 to-blue-600 text-transparent bg-clip-text transition-opacity duration-300  ${animationState.conversation ? 'opacity-100' : 'opacity-0'}`} style={width > 1400 ? {fontSize:'70px'}:{}}>Conversation
          </span>    
        </div>
        <svg className={`rotate-90 lg:rotate-0 font-monst transition-opacity duration-300 ${animationState.arrows.first ? 'opacity-100' : 'opacity-0' }`} xmlns="http://www.w3.org/2000/svg" width={width > 1000 ? '42': `${width >= 700 ? '35' : '28'}`} height={width > 1000 ? '27': `${width >= 700 ? '25' : '18'}`} viewBox="0 0 11 7" fill="none">
          <path d="M0.832031 4.65041V2.26354H5.71185V0.338135L10.4941 3.45698L5.71185 6.57582V4.65041H0.832031Z" fill="black"/>
        </svg>
        <div className='flex flex-col font-monst justify-around items-center lg:flex-row lg:justify-around lg:items-center'>
          <span className={`text-4xl font-bold sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl bg-gradient-to-r from-indigo-950 via-violet-800 to-blue-600 text-transparent bg-clip-text transition-opacity duration-300 ${animationState.documents ? 'opacity-100' : 'opacity-0'}`} style={width > 1400 ? {fontSize:'70px'}:{}}>Documents</span>          
        </div>
        <svg className={`rotate-90 font-monst lg:rotate-0 transition-opacity duration-500 ${animationState.prototypes? 'opacity-100' : 'opacity-0' }`} xmlns="http://www.w3.org/2000/svg" width={width > 1000 ? '42': `${width >= 700 ? '35' : '28'}`} height={width > 1000 ? '27': `${width >= 700 ? '25' : '18'}`} viewBox="0 0 11 7" fill="none">
          <path d="M0.832031 4.65041V2.26354H5.71185V0.338135L10.4941 3.45698L5.71185 6.57582V4.65041H0.832031Z" fill="black"/>
        </svg> 
        <div className='flex flex-col font-monst items-center lg:flex-row lg:justify-around lg:items-center overflow-visible h-auto'>
          <span 
            className={`text-4xl font-bold sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl bg-gradient-to-r from-indigo-950 via-violet-800 to-blue-600 text-transparent bg-clip-text transition-opacity duration-00 leading-tight -mb-1 ${animationState.prototypes ? 'opacity-100' : 'opacity-0'}`}
            style={width > 1400 ? {fontSize:'70px',lineHeight:'1.2'}:{lineHeight:'1.2'}}
          >
            Prototypes
          </span>
        </div>
      </div>
    </div>
  );
};

export default ContentSection;